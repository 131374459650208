import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { elastic as Menu } from 'react-burger-menu'
import fbWhite from "../images/facebook-logo.svg";
import instaWhite from "../images/instagram-logo.svg";
import sortDown from "../images/sort-down-blue.svg"
import sortDownWhite from "../images/sort-down-solid.svg"
import { StaticImage } from "gatsby-plugin-image"


import { Sticky } from 'react-sticky';


import "./MainMenu.css";

const burgerStyles = {
    bmBurgerButton: {
        display: "block",
        position: "absolute",
        width: "26px",
        height: "22px",
        left: "25px",
        top: "30px"
    },
    bmBurgerBars: {
        background: '#a53130'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0!important",
        left: "0"
    },
    bmMenu: {
        background: "rgb(27 27 27)",
        padding: "0.5em 1.5em 0",
        fontSize: "1.15em",
        minWidth: "300px"
    },
    bmMorphShape: {
        fill: 'rgb(27 27 27)'
    },
    bmItemList: {
        color: "#b8b7ad",
        // padding: "0.8em"
    },
    bmItem: {
        display: "block!important",
        marginTop: "1rem",
        outline: "none"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0!important"
    },
    bmItemList: {
        overflowY: "auto"
    }
}

function MainMenu(props) {
    const [menuState, setMenuState] = useState(false);
    let menu = props.headerMenu ? props.headerMenu.menuAcf.menu : [];

    let isMenuOpen = function (state) {
        setMenuState(state.isOpen)
    };

    // let nestCounter = 0;


    function createSubMenu(subs, parentLabel, distanceFromTop) {
        if (parentLabel !== "Υπηρεσίες" && parentLabel !== "Services" && parentLabel !== "Εξετάσεις" && parentLabel !== "Examinations") {
            return (
                <ul className={"top80"}>
                    {subs.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.url && subLink.url.includes("https://")
                                ?
                                <a href={`${subLink.url}`} target={'_blank'} className="menu-g-link">
                                    {subLink.label}
                                </a>
                                : (
                                    subLink.url
                                        ?
                                        <Link to={`/${subLink.url.replace("http://", "")}/`} className="menu-g-link">
                                            {subLink.label}
                                        </Link>
                                        :
                                        <div>

                                        </div>
                                )

                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Υπηρεσίες" || parentLabel == "Services") {
            return (
                <ul className={"top80"} >
                    {(props.services && props.services[0].services.edges) && props.services[0].services.edges.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.node.uri.includes("https://")
                                ?
                                <a href={`${subLink.node.uri}/`} className="menu-g-link">
                                    {subLink.node.title}
                                </a>
                                :
                                <Link to={`${subLink.node.uri}`} className="menu-g-link">
                                    {subLink.node.title}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Εξετάσεις" || parentLabel == "Examinations") {
            return (
                <ul className={"top80"} >
                    {(props.examinations && props.examinations[0].examinations.edges) && props.examinations[0].examinations.edges.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.node.uri.includes("https://")
                                ?
                                <a href={`${subLink.node.uri}/`} className="menu-g-link">
                                    {subLink.node.title}
                                </a>
                                :
                                <Link to={`${subLink.node.uri}`} className="menu-g-link">
                                    {subLink.node.title}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        }
    }

    function createSubMobile(subs, parentLabel) {
        if (parentLabel !== "Υπηρεσίες" && parentLabel !== "Services" && parentLabel !== "Εξετάσεις" && parentLabel !== "Examinations") {
            return (
                <ul className="pl-4 pt-2">
                    {subs.map((subLink, index) => (
                        <li key={`sub-mob-link-${index}`} className="flex" >

                            {subLink.node?.url.includes("https://")
                                ?
                                <a href={`${subLink.node.url}/`} className="py-2">
                                    {subLink.node.label}
                                </a>
                                :
                                <Link to={`/${subLink.node?.url.replace("http://", "")}/`} className="py-2" >
                                    {subLink?.node?.label}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Υπηρεσίες" || parentLabel == "Services") {
            return (
                <ul className="pl-4 pt-2">
                    {(props.services && props.services[0].services.edges) && props.services[0].services.edges.map((subLink, index) => (
                        <li key={`sub-mob-link-${index}`} className="flex">
                            {subLink.node.uri.includes("https://")
                                ?
                                <a href={`${subLink.node.uri}/`} className="py-2" style={{ whiteSpace: "pre-line" }}>
                                    {subLink.node.title}
                                </a>
                                :
                                <Link to={`${subLink.node.uri}`} className="py-2" style={{ whiteSpace: "pre-line" }}>
                                    {subLink.node.title}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        } else if (parentLabel == "Εξετάσεις" || parentLabel == "Examinations") {
            return (
                <ul className="pl-4 pt-2">
                    {/* {subs.map((subLink, index) => (
                        <li key={`sub-mob-link-${index}`} className="flex" >

                            { subLink.url && subLink.url.includes("https://")
                                ?
                                <a href={`${subLink.url}/`} className="py-2">
                                    {subLink.label}
                                </a>
                                :
                                <Link to={`/${subLink.url.replace("http://", "")}/`} className="py-2" >
                                    {subLink.label}
                                </Link>
                            }
                        </li>
                    ))} */}

                    {(props.examinations && props.examinations[0].examinations.edges) && props.examinations[0].examinations.edges.map((subLink, index) => (
                        <li key={`sub-mob-link-${index}`} className="flex" >
                            {subLink.node.uri.includes("https://")
                                ?
                                <a href={`${subLink.node.uri}/`} className="py-2" style={{ whiteSpace: "pre-line" }}>
                                    {subLink.node.title}
                                </a>
                                :
                                <Link to={`${subLink.node.uri}`} className="py-2" style={{ whiteSpace: "pre-line" }}>
                                    {subLink.node.title}
                                </Link>
                            }
                        </li>
                    ))}


                </ul>
            )
        }
    }

    function subMenuClick(index, t, j) {
        let isOpen = document.querySelectorAll(".m-dropdown-content")[index].style.display;

        if (isOpen === "none" || isOpen === "") {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "block";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(90deg)";
        } else {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "none";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(0deg)";
        }
    }

    return (
        <div className="nav-container mx-auto flex md:block justify-center" id="menuId">
            <div className="preheader hidden md:flex">
                <div className="preheader hidden md:flex justify-between px-4 container m-auto">
                    <div className="flex items-center">
                        <div>
                            {props.preHeader.telephoneNumber}
                        </div>
                        <div className="pl-4">
                            {props.preHeader.supportEmail}
                        </div>
                    </div>
                    <div className="flex items-center">
                        {/* <div className="pre-divider relative pr-4">
                            <span>{props.preHeader.hoursTitle} / </span>
                            <span>{props.preHeader.hoursLabel}</span>
                        </div> */}
                        <div className="flex">
                            <a href="https://www.facebook.com/pluspapageorgiou" target="_blank" rel="noopener"><img src={fbWhite} alt="facebook-icon" style={{ width: "15px" }} /></a>
                            <a href="https://www.instagram.com/plus_medlab/" target="_blank" rel="noopener" className="pl-2"><img src={instaWhite} alt="instagram-icon" style={{ width: "15px" }} /></a>
                        </div>
                    </div>
                </div>
            </div>

            <Sticky topOffset={500} style={{ width: "100%" }}>
                {({
                    style,
                    // the following are also available but unused in this example
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                }) => (
                    <div className="bg-white m-sticky z-20 w-full" style={style}>
                        <nav className="flex flex-row md:justify-between px-5 container m-auto relative">
                            <div className="flex flex-row justify-center md:justify-between w-full md:w-auto">
                                <div id="mainMenu" className="block md:hidden"></div>
                                <Link to={props.locale === "el" ? '/' : `/${props.locale}/`} className="flex items-center relative py-2">
                                    {/* <img className="m-auto py-2" style={{ width: "178px" }} src={logoBlue} alt="white-logo" /> */}
                                    <StaticImage
                                        // className="m-auto py-2"
                                        src="../images/logo-med-small.png"
                                        alt="Plusmedical logo"
                                        placeholder="none"
                                        quality={80}
                                        loading={"eager"}
                                        width={180}
                                        layout="constrained"
                                    />
                                </Link>
                            </div>
                            <div className="hidden md:flex items-center text-xl">
                                <ul className="hidden md:flex md:flex-row nav">
                                    {menu.map((tab, index) => {
                                        return (
                                            <li key={`hMenu-${index}`} className="pr-5">
                                                {tab.menuGroup.url === "#"
                                                    ?
                                                    <span>
                                                        {tab.menuGroup.label}
                                                    </span>
                                                    :
                                                    (tab.menuGroup.url.includes("https")
                                                        ?
                                                        <a href={`${tab.menuGroup.url}/`}>
                                                            {tab.menuGroup.label}
                                                        </a>
                                                        :
                                                        <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`}>
                                                            {tab.menuGroup.label}
                                                        </Link>
                                                    )

                                                }
                                                {tab.menuGroup.childnodes && tab.menuGroup.childnodes.length > 0 && (
                                                    createSubMenu(tab.menuGroup.childnodes, tab.menuGroup.label, distanceFromTop)
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                                {(props.availableVersions && props.availableVersions.length > 0) && (
                                    <div className="language-switcher flex items-center">
                                        {console.log(props.availableVersions)}
                                        <a href="#" style={{ fontWeight: 600 }}>
                                            {props.currentPage.language}
                                        </a>
                                        <img className="lang-sort-down" src={sortDown} alt="sort-down" />
                                        <div className="top79">
                                            {props.availableVersions.map((item, index) => (
                                                <Link key={`alt-lang-${index}`} to={item.uri === "arxiki/" ? "/" : `${item.uri}`} className="text-white menu-g-link">
                                                    {item.language.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </nav>
                    </div>
                )}
            </Sticky>
            <Menu styles={burgerStyles} isOpen={menuState} onStateChange={isMenuOpen} className={`block md:hidden ${menuState ? "visible" : "invisible"}`} >
                <div className="pb-4">
                    <Link to={props.locale === "el" ? '/' : `${props.locale}/`}>
                        {/* <img className="w-32 m-auto" style={{ width: "200px" }} src={logoWhite} alt="white-logo" /> */}
                        <StaticImage
                            // className="m-auto py-2"
                            src="../images/logo-med.png"
                            alt="Plusmedical logo"
                            placeholder="none"
                            loading={"lazy"}
                            quality={100}
                            width={210}
                            layout="constrained"
                        />
                    </Link>
                </div>

                {menu.map((tab, index) => {
                    return (
                        <div key={`mMenu-${index}`} className="menu-item text-left text-white" style={{ "marginTop": "1rem" }}>

                            {tab.menuGroup.isnested
                                ?
                                <React.Fragment>
                                    <div onClick={() => subMenuClick(index, tab.menuGroup.childnodes, tab.menuGroup.label)} className="flex justify-between items-center" >
                                        <span className="h-dropdown">
                                            {tab.menuGroup.label}
                                        </span>
                                        <img className="mob-sort-down h-chev-down" src={sortDownWhite} alt="sort-down" />
                                    </div>
                                    <div className="m-dropdown-content hidden">
                                        {tab.menuGroup.childnodes.length > 0 && (
                                            createSubMobile(tab.menuGroup.childnodes, tab.menuGroup.label)
                                        )}
                                    </div>
                                </React.Fragment>
                                :

                                <React.Fragment>
                                    {
                                        tab.menuGroup.url.includes("https")
                                            ?
                                            <a href={`${tab.menuGroup.url}/`}>
                                                {tab.menuGroup.label}
                                            </a>
                                            :
                                            <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`}>
                                                {tab.menuGroup.label}
                                            </Link>

                                    }

                                    <div className="h-chev-down hidden"></div>
                                    <div className="m-dropdown-content hidden"></div>
                                </React.Fragment>
                            }
                        </div>
                    )
                })}


                {/* <div className="menu-item text-left text-white" style={{ "marginTop": "1rem" }}>
                    <React.Fragment>
                        <div onClick={() => subMenuClick(document.querySelectorAll(".m-dropdown-content").length - 1)} className="flex justify-between items-center" >
                            <span className="h-dropdown">
                                {props.currentPage.language}
                            </span>
                            <img className="mob-sort-down h-chev-down" src={sortDownWhite} alt="sort-down" />
                        </div>
                        <div className="m-dropdown-content hidden">
                            <ul className="pl-4 pt-4">
                                {props.availableVersions.length > 0 && props.availableVersions.map((item, index) => (
                                    <li key={`sub-mob-l-link-${index}`} className="flex" >
                                        <Link key={`alt-lang-${index}`} to={item.uri === "arxiki/" ? "/" : `${item.uri}`} className="text-white">
                                            {item.language.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </React.Fragment>
                </div> */}
            </Menu>
        </div>
    )
}

export default MainMenu;