import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { elastic as Menu } from 'react-burger-menu'
import logoWhite from "../images/logo-med.png";
import fbWhite from "../images/facebook-logo-gray.svg";
import instaWhite from "../images/instagram-logo-gray.svg";

import Marker from "../images/placeholder.svg";
import Email from "../images/email.svg";
import Tele from "../images/phone-call.svg";
import { format } from 'date-fns'
import { el } from 'date-fns/locale'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";



import "./FooterMenu.css";


function FooterMenu(props) {

    console.log(props)
    const menu = props.footerMenu ? props.footerMenu.menuAcf.menu : [];
    const extra = props.footerMenu ? props.footerMenu.footerExtraAcf : "";

    useEffect(() => {
        if (document.querySelectorAll(".slash")[0]) {
            document.querySelectorAll(".slash")[0].style.display = "none";
        }
    }, [])

    return (
        <div className="footer-container mx-auto px-5">
            <div className="container m-auto flex flex-wrap lg:justify-around pt-16 pb-8 ff-cont" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.07)" }}>
                <div className="flex flex-row flex-wrap pb-8 lg:pb-0 w-full md:w-1/3 px-4 md:px-12 text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start fbr" >
                    <div>
                        <img src={Marker} style={{ width: "32px" }} alt="marker" />
                    </div>
                    <div className="pl-4 w-auto md:w-full xl:w-auto cm-width" dangerouslySetInnerHTML={{ __html: extra.address }} />
                </div>
                <div className="flex flex-row flex-wrap pb-8 lg:pb-0 w-full md:w-1/3 px-4 md:px-12 text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start fbr" >
                    <div>
                        <img src={Tele} style={{ width: "32px" }} alt="telephone" />
                    </div>
                    <div className="pl-4 w-auto md:w-full xl:w-auto" dangerouslySetInnerHTML={{ __html: extra.telephone }} />
                </div>
                <div className="flex flex-row flex-wrap pb-8 lg:pb-0 w-full md:w-1/3 px-4 md:px-12 text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start">
                    <div>
                        <img src={Email} style={{ width: "32px" }} alt="email" />
                    </div>
                    <div className="pl-4 w-auto md:w-full xl:w-auto" dangerouslySetInnerHTML={{ __html: extra.email }} />
                </div>

            </div>

            <nav className=" container m-auto flex flex-wrap lg:justify-around pt-8 pb-8">
                <div className={`flex flex-row flex-wrap justify-between pb-8 lg:pb-0 w-full px-4 md:px-12 fbr ${props.lang === "en" ? "md:w-1/2" : "md:w-1/3" }`} >
                    <StaticImage
                        // className="m-auto py-2"
                        src="../images/logo-med.png"
                        alt="Plusmedical logo"
                        placeholder="none"
                        loading={"lazy"}
                        quality={100}
                        width={210}
                        layout="constrained"
                    />

                    <div className="w-full flex justify-center mt-8">
                        <p style={{ minHeight: "164px" }}>
                            {extra.description}
                        </p>
                    </div>
                    {extra.cta.label && (
                        <div className="mt-8">
                            <a className="main-button-blue" href={extra.cta.action}>{extra.cta.label}</a>
                        </div>
                    )}
                </div>
                <div className={`items-center w-full px-4 md:px-12 pb-8 pt-4 md:pt-0 fbr ${props.lang === "en" ? "md:w-1/2" : "md:w-1/3" }`} >
                    <div className="flex flex-wrap">
                        <div className="w-full f-titles">
                            {extra.linksLabel}
                        </div>
                        <ul className="footer-links">
                            {menu.map((tab, index) => (
                                <li key={`hMenu-${index}`} className="md:pr-2 xl:pr-8 ">
                                    <Link to={tab.menuGroup.url === "/" ? "/" : `/${tab.menuGroup.url}/`} >
                                        {tab.menuGroup.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* {
                    props.lang !== "en" && (
                        <div className="items-center w-full md:w-1/3 px-4 md:px-12 pb-8">
                            <div className="w-full f-titles">
                                {extra.postsLabel}
                            </div>
                            <div>
                                <div>
                                    {props.recentPosts.edges.length > 0 && props.recentPosts.edges.map((rp, i) => (
                                        <Link to={rp.node.uri} className={`flex ${props.recentPosts.edges.length !== i + 1 ? "pb-6" : "pb-0 pt-8"}`} style={i == 0 ? ({ borderBottom: "1px solid rgba(0,0,0,.05)" }) : ({ borderBottom: "none" })} key={`related-post-${i}`}>
                                            <div>
                                                <GatsbyImage
                                                    image={rp.node.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                                    alt={rp.node.featuredImage.node.altText}
                                                    loading="lazy"
                                                    className="w-full" />
                                            </div>
                                            <div className="rp-rl-bl pl-4">
                                                <div>{format(new Date(rp.node.date), 'dd MMMM, yyyy', { locale: el })}</div>
                                                <div className="rp-rl-bl-h4">{rp.node.title}</div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                } */}

            </nav>
            <div className="text-white pt-4 pb-8 w-full flex flex-wrap justify-center md:justify-between container m-auto " style={{ borderTop: "1px solid rgba(0, 0, 0, 0.07)" }}>
                <div className="px-4 md:px-12 text-center md:text-left">
                    <div dangerouslySetInnerHTML={{ __html: extra.copyrights }} />
                </div>
                <div className="flex px-4 md:px-12 pt-4 md:pt-0">
                    <a href="https://www.facebook.com/pluspapageorgiou" target="_blank" rel="noopener"><img src={fbWhite} alt="facebook-icon" className="foo-s-icon" /></a>
                    <a href="https://www.instagram.com/plus_medlab/" target="_blank" rel="noopener" className="pl-6 md:pl-4"><img src={instaWhite} alt="instagram-icon" className="foo-s-icon" /></a>
                </div>
            </div>
        </div>
    );
}

export default FooterMenu;

export const query = graphql`fragment RecentPostsFragment on WPGraphQL_RootQueryToPostConnection {
  edges {
    node {
      uri
      slug
      title
      date
      author {
        node {
          firstName
        }
      }
      featuredImage {
        node {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 100, quality: 100, placeholder: NONE, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`