
import React from "react"
import MainMenu from "./MainMenu"
import FooterMenu from "./FooterMenu"
import { Helmet } from "react-helmet"
import GoTop from './Global/GoTop'
// import Promo from './Promo'


import { StickyContainer } from 'react-sticky';

const Layout = ({ children, headerMenu, footerMenu, recentPosts, currentPage, availableVersions, metaData, services, examinations, preHeader, }) => (
    <StickyContainer>
        <div>
            {/* && availableVersions[0] */}
            {metaData && (
                <Helmet>
                    <html lang={metaData.locale} />
                    <title>{metaData.title}</title>
                    <meta name="description" content={metaData.description} />

                    <link rel="alternate" hreflang={metaData.locale} href={metaData.canonical} />

                    <link rel="alternate" hreflang={"x-default"} href={metaData.canonical.includes("/en/") ? "https://plusmedical.gr" + availableVersions[0]?.uri : metaData.canonical} />
                    <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                    />

                    <meta property="og:title" content={metaData.fbTitle} />
                    <meta property="og:description" content={metaData.fbDescription} />
                    <meta property="og:url" content={metaData.canonical} />
                    <meta property="og:type" content={metaData.fbType} />
                    <meta property="og:locale" content={metaData.locale} />
                    <meta property="og:image" content={metaData.image} />
                    <meta property="og:image:width" content="598" />
                    <meta property="og:image:height" content="399" />

                </Helmet>
            )}


            {headerMenu && (
                <div className="w-full">
                    <MainMenu headerMenu={headerMenu} currentPage={currentPage} availableVersions={availableVersions} services={services} examinations={examinations} locale={metaData.locale} preHeader={preHeader} />
                </div>
            )}
            {children}
            {footerMenu && (
                <FooterMenu footerMenu={footerMenu} currentPage={currentPage} recentPosts={recentPosts} lang={metaData.locale} />
            )}

            <GoTop scrollStepInPx="100" delayInMs="10.50" />
            {/* <Promo /> */}

        </div>
    </StickyContainer>
)

export default Layout

            // export const query = graphql`
//   fragment SeoFragment on WPGraphQL_SEO {
//     whoWeAre {
//         title
//         content
//     }
//   }
// `
